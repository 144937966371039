import * as React from "react";
import Layout from "../../components/Layout";
import { graphql } from "gatsby";
import TopicsCard from "../../components/topicComponents/TopicCards";

const Topics = ({ data, location }) => {
  return (
    <Layout pageTitle="Topics" location={location.pathname}>
      <h1 className="text-2xl font-bold">Topics</h1>
      <div className="all-topics-grid  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9 items-stretch mt-6 mb-9">
        {data.allAirtable.edges.map(topic => (
          <div key={topic.node.id}>
            <TopicsCard data={topic.node.data} />
          </div>
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allAirtable(
      filter: { table: { eq: "book_topics" }, data: { Topics: { ne: null } } }
    ) {
      edges {
        node {
          id
          data {
            Topics
            image_url
            topic_covers {
              localFiles {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Topics;
